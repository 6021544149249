// import '@fortawesome/fontawesome-free/js/fontawesome'
// import '@fortawesome/fontawesome-free/js/solid'
// import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'
// import './vendor/jquery'
// import './vendor/bootstrap'

import _ from 'lodash';
import '../scss/app.scss';
// import Icon from '../assets/logo.svg';
// import Print from './print.js';

//import 'jquery';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'bootstrap';
//import 'bootstrap-select';

// function component() {
//   const element = document.createElement('div');
//   const btn = document.createElement('button');
//   element.innerHTML = _.join(['Hello', 'webpack'], ' ');
//   element.classList.add('hello');
//
//   element.onclick = Print.bind(null, 'Hello webpack!');
//
//   // const myIcon = new Image();
//   // myIcon.src = Icon;
//   // element.appendChild(myIcon);
//
//   return element;
// }
//document.body.appendChild(component());

// https://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed
// var waitForFinalEvent = (function() {
//   var timers = {};
//   return function(callback, ms, uniqueId) {
//     if (!uniqueId) {
//       uniqueId = "Don't call this twice without a uniqueId";
//     }
//     if (timers[uniqueId]) {
//       clearTimeout(timers[uniqueId]);
//     }
//     timers[uniqueId] = setTimeout(callback, ms);
//   };
// })();
// $(window).resize(function() {
//   waitForFinalEvent(function() {
//     console.log('Resize...');
//     if (document.documentElement.clientWidth < 960) {
//       // $(document).on('click', 'button.navbar-toggler',
//       //   function() {
//       //     $(".overlay").fadeToggle(200);
//       //     // $(this).toggleClass('btn-open').toggleClass('btn-close');
//       //     //        $(this).toggleClass('collapsed');
//       //   }
//       // );
//     }
//   }, 500, "some unique string");
// });

$('.toTop').on('click', function(event) {
event.preventDefault();
$('html, body').animate({ scrollTop: 0 }, 'slow');
});

function registerOverlayToggler() {
  $("button.navbar-toggler").click(function() {
    // $(".overlay").fadeToggle(200);
    console.log("button.navbar-toggler.click");
    //$("#header .navbar").toggleClass('fixed-top');
    $("#header").toggleClass('closed');
    //$('html, body').animate({ scrollTop: 0 }, 'fast');

    // $(this).toggleClass('btn-open').toggleClass('btn-close');
    //        $(this).toggleClass('collapsed');
  });
  // $('.overlay').on('click', function() {
  //   $(".overlay").fadeToggle(200);
  //   // $(".menu-btn a").toggleClass('btn-open').toggleClass('btn-close');
  //   //$(this).toggleClass('collapsed');
  // });
}

$(document).ready(function() {

  // if (typeof Privacybanner === 'object') {
  //   Privacybanner.init();
  //   console.log('Privacybanner.init');
  // }

	//$('select.selectpicker').selectpicker();
	
  registerOverlayToggler();

  function currentScreenSizeDetectorMethod() {
    if ($('#hamburger').is(':visible')) {
//      $('#collapsibleNavbar').removeClass('dropdown-hover-all');
      $('body').addClass('hamburger');
      $('body').removeClass('no-hamburger');
      console.log('HAMBURGEr!');
    } else {
      $('body').removeClass('hamburger');
      $('body').addClass('no-hamburger');
      console.log('no ham.');
    };
  }

  // // When the user scrolls the page, execute myFunction
  // window.onscroll = function() {myFunction()};
  // // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
  // function myFunction() {
  //   // Get the header
  //   var header = document.getElementById("#header .navbar");
  //   // Get the offset position of the navbar
  //   var sticky = header.offsetTop;
  //   if (window.pageYOffset > sticky) {
  //     $("#header .navbar").addClass('fixed-top');
  //     // header.classList.add("sticky");
  //   } else {
  //     $("#header .navbar").removeClass('fixed-top');
  //     //header.classList.remove("sticky");
  //   }
  // }

  // Bind screen size and orientation change
  $(window).bind("resize orientationchange", function() {
    // Execute screen detection
    currentScreenSizeDetectorMethod();
  });
  // Execute screen detection on page initialize
  currentScreenSizeDetectorMethod();

  // $('.navbar .dropdown > a').click(function() {
  //   location.href = this.href;
  //   console.log('click:'+this.href);
  // });
  $('#collapsibleNavbar.no-hamburger .dropdown-menu a.dropdown-toggle').click(function() {
    // console.log('click:'+this.href);
    // alert('click:'+this.href);
    location.href = this.href;
  });
  $('#collapsibleNavbar.no-hamburger .dropdown a.dropdown-toggle').click(function() {
    location.href = this.href;
  });
  // $('#collapsibleNavbar.hamburger .dropdown a.dropdown-toggle').click(function() {
  //   return true;
  // });

  $('.dropdown-menu').hover(function() {
        console.log('hover');
        $(this).prev().addClass('active');
    },
    function() {
      console.log('hover-out');
        $(this).prev().removeClass('active');
    });

  // https://github.com/bootstrapthemesco/bootstrap-4-multi-dropdown-navbar/blob/master/js/bootstrap-4-navbar.js
  // $('.OFFFFdropdown-menu a.dropdown-toggle').on('click', function(e) {
  //   var $el = $(this);
  //   $el.toggleClass('active-dropdown');
  //   var $parent = $(this).offsetParent(".dropdown-menu");
  //   if (!$(this).next().hasClass('show')) {
  //     $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
  //   }
  //   var $subMenu = $(this).next(".dropdown-menu");
  //   $subMenu.toggleClass('show');
  //
  //   $(this).parent("li").toggleClass('show');
  //
  //   $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
  //     $('.dropdown-menu .show').removeClass("show");
  //     $el.removeClass('active-dropdown');
  //   });
  //
  //   if (!$parent.parent().hasClass('navbar-nav')) {
  //     $el.next().css({
  //       "top": $el[0].offsetTop,
  //       "left": $parent.outerWidth() - 4
  //     });
  //   }
  //   return false;
  // });


  // fake dropdown select
  // https://stackoverflow.com/questions/13437446/how-to-display-selected-item-in-bootstrap-button-dropdown-title
  $(".filter-row .dropdown-menu a").click(function(e) {
    // if (e.target !== this)
    //     return;
    var $el = $(this);
    var t  = $el.text();
    var $b = $el.closest('.dropdown').find('.btn');
    $b.text(t);
    $b.val(t);
    // $(".btn:first-child").text(t);
    // $(".btn:first-child").val(t);
  });

  //
  // $('.navbar-collapse a').on('click', function () {
  //     $(".overlay").fadeToggle(200);
  //     // $(".menu-btn a").toggleClass('btn-open').toggleClass('btn-close');
  //     $(this).toggleClass('collapsed');
  // });


  // https://stackoverflow.com/questions/44467377/bootstrap-4-multilevel-dropdown-inside-navigation
  //   $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
  //   if (!$(this).next().hasClass('show')) {
  //     $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
  //   }
  //   var $subMenu = $(this).next('.dropdown-menu');
  //   $subMenu.toggleClass('show');
  //
  //
  //   $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
  //     $('.dropdown-submenu .show').removeClass('show');
  //   });
  //
  //
  //   return false;
  // });

  // https://github.com/dallaslu/bootstrap-4-multi-level-dropdown
  // https://jsfiddle.net/dallaslu/adky6jvs/
  // $.fn.dropdown = (function() {
  //   var $bsDropdown = $.fn.dropdown;
  //   return function(config) {
  //     if (typeof config === 'string' && config === 'toggle') { // dropdown toggle trigged
  //       $('.has-child-dropdown-show').removeClass('has-child-dropdown-show');
  //       $(this).closest('.dropdown').parents('.dropdown').addClass('has-child-dropdown-show');
  //     }
  //     var ret = $bsDropdown.call($(this), config);
  //     $(this).off('click.bs.dropdown'); // Turn off dropdown.js click event, it will call 'this.toggle()' internal
  //     return ret;
  //   }
  // })();
  //
  // $(function() {
  //   $('.dropdown [data-toggle="dropdown"]').on('click', function(e) {
  //     $(this).dropdown('toggle');
  //     e.stopPropagation(); // do not fire dropdown.js click event, it will call 'this.toggle()' internal
  //   });
  //   $('.dropdown').on('hide.bs.dropdown', function(e) {
  //     if ($(this).is('.has-child-dropdown-show')) {
  //       $(this).removeClass('has-child-dropdown-show');
  //       e.preventDefault();
  //     }
  //     e.stopPropagation(); // do not need pop in multi level mode
  //   });
  // });
  //
  // // for hover
  // $('.dropdown-hover').on('mouseenter', function() {
  //   if (!$(this).hasClass('show')) {
  //     $('>[data-toggle="dropdown"]', this).dropdown('toggle');
  //   }
  // });
  // $('.dropdown-hover').on('mouseleave', function() {
  //   if ($(this).hasClass('show')) {
  //     $('>[data-toggle="dropdown"]', this).dropdown('toggle');
  //   }
  // });
  // $('.dropdown-hover-all').on('mouseenter', '.dropdown', function() {
  //   if (!$(this).hasClass('show')) {
  //     $('>[data-toggle="dropdown"]', this).dropdown('toggle');
  //     console.log('toggle enter.');
  //
  //   }
  // });
  // $('.dropdown-hover-all').on('mouseleave', '.dropdown', function() {
  //   if ($(this).hasClass('show')) {
  //     $('>[data-toggle="dropdown"]', this).dropdown('toggle');
  //     console.log('toggle leave.');
  //
  //   }
  // });

  $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
      if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
      }
      var $subMenu = $(this).next(".dropdown-menu");
      $subMenu.toggleClass('show');

      $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
          $('.dropdown-submenu .show').removeClass("show");
      });
      return false;
  });

	$('body.no-hamburger a.nav-link.dropdown-toggle').on('click', function(e) {
		var href = $(this).attr('href');
		console.log(href);
		window.location.href = href;
	});

	 $('.hola-select').on('change', function () {
         var url = $(this).val(); // get selected value
         if (url) { // require a URL
             window.location = url; // redirect
         }
         return false;
     });
});
